/* eslint-disable */

import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import data from './views/dashboard/datatable-data'
import Verte from 'verte';
// import VTitle from 'v-title';
import 'verte/dist/verte.css';
import ToggleButton from 'vue-js-toggle-button'
import CryptoJS from 'crypto-js'
import '@mdi/font/css/materialdesignicons.css'
// import Vue2Editor from 'vue2-editor'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import * as axios from 'axios';


const keyValue = "TickcatsEncrpyt2023!" // 秘钥
const ivKey = "InnPre55ion"; // Convert string to WordArray

Vue.component(Verte.name, Verte);

// import { saveAs } from 'file-saver'

// Vue.use(VTitle)
Vue.use(VueCookies)
    // Vue.use(Vue2Editor)
Vue.use(ToggleButton)
Vue.use(VueTimepicker)

Vue.config.productionTip = false;

Vue.prototype.format = new function() {
    this.transformDate = function(date) {
            // return yyyy-mm-dd
            let month = date.getMonth() + 1
            if (month <= 9) {
                month = '0' + month
            }

            let day = date.getDate()
            if (day <= 9) {
                day = '0' + date
            }
            return `${date.getFullYear()}-${month}-${day}`
        },
        this.getLastDay = function() {
            let date = new Date()
            let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            let month = lastDay.getMonth() + 1
            if (month <= 9) {
                month = '0' + month
            }
            return `${lastDay.getFullYear()}-${month}-${lastDay.getDate()}`
        }
}

Vue.prototype.XHR = new function() {

    this.uploadImage = function(type, url, contentType, dataHeader, dataObj, fnSuccess, fnFail,
        fnRequestError, fnStatusError, fnRequestProgress, allowEmptyResponse, isAsync) {
        // Requester.addRequest()

        const dataName = url
        let data = null
        const request = new XMLHttpRequest()
        request.open(type, url, isAsync !== false)

        if (isAsync !== false) {
            request.timeout = 60 * 1000 // 1 min
        }

        if (dataHeader) {
            for (const e in dataHeader) {
                request.setRequestHeader(e, dataHeader[e])
            }
        }

        console.log("request")
        console.log(dataObj)
        if (dataObj) {
            data = new FormData()
            data.append('file', dataObj) //JSON.stringify(dataObj))
        }

        function jsonCallback() {
            let error, response;

            function temp() {
                try {
                    return JSON.parse(request.responseText)
                } catch (e) {
                    error = e
                    return false;
                }
            };
            response = temp()
            if (!response) {
                console.warn(type + ' XHR: Request Failed (JSON Parse Exception) - [Key: ' + dataName +
                    ']')
                console.warn(request.responseText)
                if (fnFail) fnFail('JSON Parse Exception', error)
            } else {
                checkEmptyCallback(response)
            }
        }

        function checkEmptyCallback(response) {

            if ((allowEmptyResponse && (response == null || response == '')) ||
                (!allowEmptyResponse && response != null && response != '')) {
                console.log(type + ' XHR: Request Succeed - [Key: ' + dataName + ']')
                console.log(response)
                if (fnSuccess) fnSuccess(response)
            } else {
                console.warn(type + ' XHR: Request Failed (Invalid Response) - [Key: ' + dataName + ']')
                console.log(response)
                if (fnFail) fnFail(response)
            }
        }


        request.onload = function() {
            if (request.readyState === 4) {
                if (request.status === 200) {
                    if (contentType == 'json') {
                        jsonCallback()
                    } else {
                        checkEmptyCallback(request.responseText)
                    }
                } else {
                    console.warn(type + ' XHR: Status Error (status: ' + request.status + ') - [Key: ' +
                        dataName + ']')
                    if (fnStatusError) fnStatusError(request.status)
                }
            } else {
                console.warn(type + ' XHR: Status Error (readyState: ' + request.readyState +
                    ') - [Key: ' + dataName + ']')
                if (fnStatusError) fnStatusError(request.readyState)
            }
            // Requester.finishRequest()
        }

        let progress = 0;

        function progressCallback(ev) {
            if (ev.lengthComputable) {
                let percent = ev.loaded / ev.total * 100
                progress = Math.max(progress, percent)
                if (fnRequestProgress) fnRequestProgress(progress, ev.loaded, ev.total)
            } else {
                if (fnRequestProgress) fnRequestProgress(progress, ev.loaded, null)
            }
        }

        if (dataObj) {
            request.upload.onprogress = function(ev) {
                progressCallback(ev)
            }
        } else {
            request.onprogress = function(ev) {
                progressCallback(ev)
            }
        }

        request.onabort = function() {
            // Requester.finishRequest()
            console.warn(type + ' XHR: Connection Failed (Abort) - [Key: ' + dataName + ']')
            if (fnRequestError) fnRequestError('Abort')
        };
        request.onerror = function() {
            // Requester.finishRequest()
            console.warn(type + ' XHR: Connection Failed (Error) - [Key: ' + dataName + ']')
            if (fnRequestError) fnRequestError('Error')
        };
        request.ontimeout = function() {
            // Requester.finishRequest()
            console.warn(type + ' XHR: Connection Failed (Timeout) - [Key: ' + dataName + ']')
            if (fnRequestError) fnRequestError('Timeout')
        };

        dataObj ? request.send(data) : request.send()
            // dataObj ? request.send(JSON.stringify(dataObj)) : request.send()
    };
    this.tcApiUpload = function(dataObj, fnSuccess, fnFail, fnRequestError, fnStatusError,
        fnRequestProgress, isAsync) {
        if (arguments.length < 8) {
            if (arguments.length < 7) {
                if (arguments.length < 6) {
                    if (arguments.length < 5) {
                        if (arguments.length < 4) {
                            fnFail = null;
                        }
                        fnRequestError = fnFail;
                    }
                    fnStatusError = fnFail;
                }
                fnRequestProgress = null;
            }
            isAsync = true;
        }

        function fnApiSuccess(response) {
            console.log("fnApiSuccess")
            console.log(response)
            if (response['cookies'] !== undefined) {
                console.log("cookies")
                console.log(response['cookies'])
                window.$cookies.set('tickcats_auth', response['cookies'])
            }
            if (response['result'] !== undefined && response.result == 'success') {
                fnSuccess(response.result, response.data)
            } else if (fnFail) {
                fnFail(response.result, response.data)
            }
        }

        function fnApiFail(response) {
            if (fnFail) {
                if (response['result'] !== undefined) {
                    fnFail(response.result, response.data)
                } else {
                    fnFail('', response)
                }
            }
        }
        // request.setRequestHeader("withCredentials", "true")

        // if (window.$cookies.get('tickcats_auth') != null) {
        //     dataObj['cookies'] = window.$cookies.get('tickcats_auth')
        // } else {
        //     console.log('tickcats_auth null')
        //         // if (dataObj['staff_login'] == null) {
        //     if (apiFuc != "admin/login") {
        //         console.log(dataObj)
        //         localStorage.removeItem('vuex');
        //         // dev commented, reload if not logged in
        //         // this.$router.push('/login')
        //         location.assign('/login');
        //     }
        // }
        console.log("backend post dataObj")
        console.log(dataObj)
            // this.post(
            //     'https://tickcats.innpressionhost.com/api/upload',
            //     'json', {}, //{ 'Content-type': 'application/json; charset=utf-8' },
            //     dataObj, fnApiSuccess, fnApiFail, fnRequestError, fnStatusError, fnRequestProgress,
            //     false, // allowEmptyResponse
            //     isAsync !== false
            // )
        const formData = new FormData();
        formData.append("file", dataObj);

        axios.post('https://tickcats.innpressionhost.com/api/upload', formData)
            // get data
            .then(res => {
                console.log("response 1")
                let response = res.data
                console.log(response)
                if (response['cookies'] !== undefined) {
                    // console.log("cookies")
                    // console.log(response['cookies'])
                    window.$cookies.set('tickcats_auth', response['cookies'])

                }
                // if (response['remember-token'] !== undefined) {
                //     window.$cookies.set('remember-token', response['remember-token'])
                // }
                if (response['result'] !== undefined && response.result == 'success') {
                    fnSuccess(response.result, response.data)
                } else if (fnFail) {
                    fnFail(response.result, response.data)
                }
            });
        // // add url field
        // .then(response => {
        //   console.log("response 2")
        //   console.log(response)
        //     if (fnFail) {
        //         if (response['result'] !== undefined) {
        //             fnFail(response.result, response.data)
        //         } else {
        //             fnFail('', response)
        //         }
        //     }
        // });

        // this.uploadImage('POST', 'https://tickcats.innpressionhost.com/api/upload',
        //     'file', { 'Content-Type': 'multipart/form-data' },
        //     dataObj, fnApiSuccess, fnApiFail, fnRequestError, fnStatusError, fnRequestProgress,
        //     false, // allowEmptyResponse
        //     isAsync !== false)
    };

    // ---

    this.request = function(type, url, contentType, dataHeader, dataObj, fnSuccess, fnFail,
        fnRequestError, fnStatusError, fnRequestProgress, allowEmptyResponse, isAsync) {
        // Requester.addRequest()

        const dataName = url
        let data = null
        const request = new XMLHttpRequest()
        request.open(type, url, isAsync !== false)
        request.withCredentials = true;

        if (isAsync !== false) {
            request.timeout = 60 * 1000 // 1 min
        }

        if (dataHeader) {
            for (const e in dataHeader) {
                console.log(e)
                console.log(dataHeader[e])
                request.setRequestHeader(e, dataHeader[e])
            }
        }

        console.log("window.$cookies.get('remember-token')")
        console.log(window.$cookies.get('remember-token'))
        if (window.$cookies.get('remember-token') != null) {
            request.setRequestHeader("Cookie", "test")
            request.setRequestHeader("Cookie", "remember-token=" + window.$cookies.get('remember-token'))
            console.log("remember-token=" + window.$cookies.get('remember-token'))
                // request.cookies = { "remember-token": window.$cookies.get('remember-token') };
        }

        console.log("dataHeader")
        console.log(dataHeader)
        if (dataObj) {
            data = new FormData()
            data.append('data', JSON.stringify(dataObj))
                // for (let e in dataObj) {
                //     console.log(e)
                //     console.log(dataObj[e])
                //     data.append(e, dataObj[e])
                // }
                // if (type == 'POST') {
                // 	if (dqa('meta[name="xsrf-token"]').length) {
                // 		const token = dq('meta[name="xsrf-token"]').content;
                // 		if (token != '') {
                // 			data.append('token', JSON.stringify(token))
                // 		}
                // 		// request.withCredentials = true;
                // 	}
                // }
                // console.log(data)
        }

        function jsonCallback() {
            let error, response;

            function temp() {
                try {
                    return JSON.parse(request.responseText)
                } catch (e) {
                    error = e
                    return false;
                }
            };
            response = temp()
            if (!response) {
                console.warn(type + ' XHR: Request Failed (JSON Parse Exception) - [Key: ' + dataName +
                    ']')
                console.warn(request.responseText)
                if (fnFail) fnFail('JSON Parse Exception', error)
            } else {
                checkEmptyCallback(response)
            }
        }

        function checkEmptyCallback(response) {

            if ((allowEmptyResponse && (response == null || response == '')) ||
                (!allowEmptyResponse && response != null && response != '')) {
                console.log(type + ' XHR: Request Succeed - [Key: ' + dataName + ']')
                console.log(response)
                if (fnSuccess) fnSuccess(response)
            } else {
                console.warn(type + ' XHR: Request Failed (Invalid Response) - [Key: ' + dataName + ']')
                console.log(response)
                if (fnFail) fnFail(response)
            }
        }


        request.onload = function() {
            console.log("onload");
            console.log(request);
            // console.log(request.getResponseHeader("Date"));
            // console.log(request.getResponseHeader("Set-Cookie"));
            if (request.readyState === 4) {
                if (request.status === 200) {
                    if (contentType == 'json') {
                        jsonCallback()
                    } else {
                        checkEmptyCallback(request.responseText)
                    }
                } else {
                    console.warn(type + ' XHR: Status Error (status: ' + request.status + ') - [Key: ' +
                        dataName + ']')
                    if (fnStatusError) fnStatusError(request.status)
                }
            } else {
                console.warn(type + ' XHR: Status Error (readyState: ' + request.readyState +
                    ') - [Key: ' + dataName + ']')
                if (fnStatusError) fnStatusError(request.readyState)
            }
            // Requester.finishRequest()
        }

        let progress = 0;

        function progressCallback(ev) {
            if (ev.lengthComputable) {
                let percent = ev.loaded / ev.total * 100
                progress = Math.max(progress, percent)
                if (fnRequestProgress) fnRequestProgress(progress, ev.loaded, ev.total)
            } else {
                if (fnRequestProgress) fnRequestProgress(progress, ev.loaded, null)
            }
        }

        if (dataObj) {
            request.upload.onprogress = function(ev) {
                progressCallback(ev)
            }
        } else {
            request.onprogress = function(ev) {
                progressCallback(ev)
            }
        }

        request.onabort = function() {
            // Requester.finishRequest()
            console.warn(type + ' XHR: Connection Failed (Abort) - [Key: ' + dataName + ']')
            if (fnRequestError) fnRequestError('Abort')
        };
        request.onerror = function() {
            // Requester.finishRequest()
            console.warn(type + ' XHR: Connection Failed (Error) - [Key: ' + dataName + ']')
            if (fnRequestError) fnRequestError('Error')
        };
        request.ontimeout = function() {
            // Requester.finishRequest()
            console.warn(type + ' XHR: Connection Failed (Timeout) - [Key: ' + dataName + ']')
            if (fnRequestError) fnRequestError('Timeout')
        };

        dataObj ? request.send(data) : request.send()
            // dataObj ? request.send(JSON.stringify(dataObj)) : request.send()
    };
    this.post = function(url, contentType, dataHeader, dataObj, fnSuccess, fnFail, fnRequestError, fnStatusError, fnRequestProgress, allowEmptyResponse, isAsync) {
        // console.log("pre post")
        // console.log(dataObj)
        this.request('POST', url, contentType, dataHeader, dataObj, fnSuccess, fnFail,
                fnRequestError, fnStatusError, fnRequestProgress, allowEmptyResponse, isAsync)
            // try {
            // 	this.request('POST', url, contentType, dataHeader, dataObj, fnSuccess, fnFail, fnRequestError, fnStatusError, fnRequestProgress, allowEmptyResponse, isAsync)
            // } catch(err) {
            // 	console.warn('POST XHR: Connection Failed (Exception) - [Key: ' + url + ']')
            // 	if (fnRequestError) fnRequestError()
            // }
    };
    this.api = function(dataObj, fnSuccess, fnFail, fnRequestError, fnStatusError,
        fnRequestProgress, isAsync) {
        if (arguments.length < 7) {
            if (arguments.length < 6) {
                if (arguments.length < 5) {
                    if (arguments.length < 4) {
                        if (arguments.length < 3) {
                            fnFail = null;
                        }
                        fnRequestError = fnFail;
                    }
                    fnStatusError = fnFail;
                }
                fnRequestProgress = null;
            }
            isAsync = true;
        }

        function fnApiSuccess(response) {
            console.log("fnApiSuccess")
            console.log(response)
            if (response['cookies'] !== undefined) {
                // console.log("cookies")
                // console.log(response['cookies'])
                // window.$cookies.set('tickcats_auth', response['cookies'])

            }
            if (response['result'] !== undefined && response.result == 'success') {
                fnSuccess(response.result, response.data)
            } else if (fnFail) {
                fnFail(response.result, response.data)
            }
        }

        function fnApiFail(response) {
            if (fnFail) {
                if (response['result'] !== undefined) {
                    fnFail(response.result, response.data)
                } else {
                    fnFail('', response)
                }
            }
        }
        // request.setRequestHeader("withCredentials", "true")

        if (window.$cookies.get('tickcats_auth') != null) {
            dataObj['cookies'] = window.$cookies.get('tickcats_auth')
        } else {
            console.log('tickcats_auth null')
            if (dataObj['staff_login'] == null) {
                console.log(dataObj)
                localStorage.removeItem('vuex');
                // dev commented, reload if not logged in
                location.reload();
            }
        }
        this.post(
            window.location.hostname.indexOf('tickcats.innpressionhost.com') < 0 ?
            'https://tickcats.innpressionhost.com/api' :
            // 'https://tickcats.innpressionhost.com/api' :
            'https://tickcats.innpressionhost.com/api',
            'application/json', dataHeader, // {'Content-type': 'application/json; charset=utf-8'}, 
            dataObj, fnApiSuccess, fnApiFail, fnRequestError, fnStatusError, fnRequestProgress,
            false, // allowEmptyResponse
            isAsync !== false
        )
    };
    this.tcApi = function(apiFuc, dataObj, fnSuccess, fnFail, fnRequestError, fnStatusError,
        fnRequestProgress, isAsync) {
        if (arguments.length < 8) {
            if (arguments.length < 7) {
                if (arguments.length < 6) {
                    if (arguments.length < 5) {
                        if (arguments.length < 4) {
                            fnFail = null;
                        }
                        fnRequestError = fnFail;
                    }
                    fnStatusError = fnFail;
                }
                fnRequestProgress = null;
            }
            isAsync = true;
        }

        function fnApiSuccess(response) {
            console.log("fnApiSuccess")
            console.log(response)
            if (response['cookies'] !== undefined) {
                console.log("cookies")
                console.log(response['cookies'])
                window.$cookies.set('tickcats_auth', response['cookies'])

            }
            // if (response['remember-token'] !== undefined) {
            //     window.$cookies.set('remember-token', response['remember-token'])
            // }
            if (response['result'] !== undefined && response.result == 'success') {
                fnSuccess(response.result, response.data)
            } else if (fnFail) {
                fnFail(response.result, response.data)
            }
        }

        function fnApiFail(response) {
            if (fnFail) {
                if (response['result'] !== undefined) {
                    fnFail(response.result, response.data)
                } else {
                    fnFail('', response)
                }
            }
        }
        // request.setRequestHeader("withCredentials", "true")

        if (window.$cookies.get('tickcats_auth') != null) {
            dataObj['cookies'] = window.$cookies.get('tickcats_auth')
        } else {
            console.log('tickcats_auth null')
                // if (dataObj['staff_login'] == null) {
            if (apiFuc != "admin/login") {
                console.log(dataObj)
                localStorage.removeItem('vuex');
                // dev commented, reload if not logged in
                // this.$router.push('/login')
                location.assign('/login');
            }
        }
        let dataHeader = [];
        console.log("window.$cookies.get('remember-token')")
        console.log(window.$cookies.get('remember-token'))
        if (window.$cookies.get('remember-token') != null) {
            // window.$cookies.set('remember-token', response['remember-token'])
            dataHeader['Cookie'] = "remember-token=" + window.$cookies.get('remember-token')
        }
        // console.log("backend post dataObj")
        // console.log(dataObj)
        this.post(
            'https://tickcats.innpressionhost.com/api/' + apiFuc,
            'json', dataHeader, //{ 'Content-type': 'application/json; charset=utf-8' },
            dataObj, fnApiSuccess, fnApiFail, fnRequestError, fnStatusError, fnRequestProgress,
            false, // allowEmptyResponse
            isAsync !== false
        )
    };
    this.mediaDir = function() {
        return window.location.hostname.indexOf('tickcats.innpressionhost.com') < 0 ?
            'https://tickcats.innpressionhost.com/media/' :
            'https://tickcats.innpressionhost.com/media/';
    }
};

Vue.prototype.FakeBackend = new function() {
    this.api = function(dataObj, fnSuccess, fnFail) {
        let jsonFile = '';
        let fakeBackendProcess = null;

        function getJsonFileName(dataType) {
            switch (dataType) {
                case 'user_data':
                    jsonFile = 'user-data';
                    break;
                case 'written_exam_data':
                    jsonFile = 'written-exam-data';
                    break;
                case 'road_exam_data':
                    jsonFile = 'road-exam-data';
                    break;
                default:
                    break;
            }
        }

        console.log(dataObj);

        if (dataObj.hasOwnProperty('get_all_data_by_data_type')) {
            const payload = JSON.parse(dataObj.get_all_data_by_data_type);
            if (payload.hasOwnProperty('data_type')) {
                getJsonFileName(payload.data_type);
            }

            fakeBackendProcess = function(json) {
                let data = json;
                let pageLimit = 10;
                let pageNo = 1;
                let sortAsc = false;

                if (payload.hasOwnProperty('sort_dir') && payload.sort_dir === 'asc') {
                    sortAsc = true;
                    data.sort((a, b) => a.id - b.id);
                } else {
                    sortAsc = false;
                    data.sort((a, b) => b.id - a.id);
                }

                if (payload.hasOwnProperty('search_key') && payload.hasOwnProperty('search_value')) {
                    if (jsonFile === 'written-exam-data') {
                        switch (payload.search_key) {
                            case 'course_code':
                                const searchText = payload.search_value.toLowerCase();
                                data = data.filter(e => {
                                    return (e.course_data.course_code.toLowerCase()).indexOf(searchText) >= 0 ||
                                        (e.user_data.email.toLowerCase()).indexOf(searchText) >= 0 ||
                                        (e.user_data.phone.toLowerCase()).indexOf(searchText) >= 0;
                                });
                                break;
                            default:
                                break;
                        }
                    } else if (jsonFile === 'road-exam-data') {
                        switch (payload.search_key) {
                            case 'course_code':
                                const searchText = payload.search_value.toLowerCase();
                                data = data.filter(e => {
                                    return (e.user_data.email.toLowerCase()).indexOf(searchText) >= 0 ||
                                        (e.user_data.phone.toLowerCase()).indexOf(searchText) >= 0;
                                });
                                break;
                            default:
                                break;
                        }
                    }
                }

                if (payload.hasOwnProperty('start_date') && payload.hasOwnProperty('end_date')) {
                    if (jsonFile === 'written-exam-data' || jsonFile === 'road-exam-data') {
                        const startDate = new Date(payload.start_date + 'T00:00:00Z').getTime();
                        const endDate = new Date(payload.end_date + 'T23:59:59Z').getTime();
                        data = data.filter(e => {
                            const itemDate = new Date(e.exam_date).getTime();
                            return itemDate >= startDate && itemDate <= endDate;
                        });
                    }
                } else if (payload.hasOwnProperty('start_date')) {
                    if (jsonFile === 'written-exam-data' || jsonFile === 'road-exam-data') {
                        const startDate = new Date(payload.start_date + 'T00:00:00Z').getTime();
                        data = data.filter(e => {
                            const itemDate = new Date(e.exam_date).getTime();
                            return itemDate >= startDate;
                        });
                    }
                } else if (payload.hasOwnProperty('end_date')) {
                    if (jsonFile === 'written-exam-data' || jsonFile === 'road-exam-data') {
                        const endDate = new Date(payload.end_date + 'T23:59:59Z').getTime();
                        data = data.filter(e => {
                            const itemDate = new Date(e.exam_date).getTime();
                            return itemDate <= endDate;
                        });
                    }
                }

                if (payload.hasOwnProperty('sort_key')) {
                    switch (payload.sort_key) {
                        case 'create_date':
                        case 'exam_date':
                            if (sortAsc) {
                                data.sort((a, b) => new Date(a[payload.sort_key]).getTime() - new Date(b[payload.sort_key]).getTime());
                            } else {
                                data.sort((a, b) => new Date(b[payload.sort_key]).getTime() - new Date(a[payload.sort_key]).getTime());
                            }
                            break;
                        case 'last_name':
                        case 'first_name':
                        case 'email':
                        case 'phone':
                            if (jsonFile === 'written-exam-data' || jsonFile === 'road-exam-data') {
                                if (sortAsc) {
                                    data.sort((a, b) => {
                                        const ax = a.user_data[payload.sort_key].toLowerCase();
                                        const bx = b.user_data[payload.sort_key].toLowerCase();
                                        if (ax < bx) { return -1; }
                                        if (ax > bx) { return 1; }
                                        return 0;
                                    });
                                } else {
                                    data.sort((a, b) => {
                                        const ax = b.user_data[payload.sort_key].toLowerCase();
                                        const bx = a.user_data[payload.sort_key].toLowerCase();
                                        if (ax < bx) { return -1; }
                                        if (ax > bx) { return 1; }
                                        return 0;
                                    });
                                }
                            }
                            break;
                        default:
                            if (data.length > 0 && data[0][payload.sort_key] !== undefined) {
                                if (typeof data[0][payload.sort_key] === 'string' || data[0][payload.sort_key] instanceof String) {
                                    if (sortAsc) {
                                        data.sort((a, b) => {
                                            const ax = a[payload.sort_key].toLowerCase();
                                            const bx = b[payload.sort_key].toLowerCase();
                                            if (ax < bx) { return -1; }
                                            if (ax > bx) { return 1; }
                                            return 0;
                                        });
                                    } else {
                                        data.sort((a, b) => {
                                            const ax = b[payload.sort_key].toLowerCase();
                                            const bx = a[payload.sort_key].toLowerCase();
                                            if (ax < bx) { return -1; }
                                            if (ax > bx) { return 1; }
                                            return 0;
                                        });
                                    }
                                }
                            }
                            break;
                    }
                }

                if (payload.hasOwnProperty('filter_page')) {
                    pageNo = payload.filter_page;
                }
                if (payload.hasOwnProperty('filter_limit')) {
                    pageLimit = payload.filter_limit;
                }


                fnSuccess('success', {
                    item_count: data.length,
                    item_list: data.slice(pageLimit * (pageNo), pageLimit * (pageNo + 1))
                });
            }
        } else if (dataObj.hasOwnProperty('get_single_data_by_data_type_and_id')) {
            const payload = JSON.parse(dataObj.get_single_data_by_data_type_and_id);
            if (payload.hasOwnProperty('data_type')) {
                getJsonFileName(payload.data_type);
            }

            fakeBackendProcess = function(json) {
                if (payload.hasOwnProperty('id')) {
                    const found = json.find(e => e.id === payload.id);
                    if (found !== undefined) {
                        fnSuccess('success', found);
                    } else {
                        fnFail('failed', 'data does not exist');
                    }
                } else {
                    fnFail('failed', 'missing must key');
                }
            }
        }

        let filepath = '/cms/fakedata/';
        if (window.location.hostname.indexOf('localhost') >= 0) {
            filepath = '/fakedata/';
        }

        fetch(filepath + jsonFile + '.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error("HTTP error " + response.status);
                }
                return response.json();
            })
            .then(json => {
                if (fakeBackendProcess !== null) {
                    setTimeout(() => {
                        fakeBackendProcess(json);
                    }, Math.floor(Math.random() * (500 - 150 + 1)) + 150);
                }
            })
            .catch(function() {
                fnFail('failed', 'fetch() Exception');
            })
    }
};

Vue.prototype.Formatter = {
    filter: {
        numOnly: function(s) {
            return s.replace(/[^0-9]/gi, '');
        },
        alphaOnly: function(s) {
            return s.replace(/[^a-z]/gi, '');
        },
        alphaNumeric: function(s) {
            return s.replace(/[^a-z0-9]/gi, '');
        },
        noWhiteSpace: function(s) {
            return s.replace(/\s/g, '');
        }
    },
    escape: {
        html: function(s) {
            return s.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g,
                "&quot;").replace(/'/g, "&#039;");
        },
        regex: function(s) {
            return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        },
    },
    convert: {
        byte: function(n, p) {
            if (n === 0) return '0B';
            var p = p || 1;
            const k = 1024;
            const s = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const i = Math.floor(Math.log(n) / Math.log(k));
            return parseFloat((n / Math.pow(k, i)).toFixed(p)) + s[i];
        }, // size int, precision (1)
    },
    replace: {
        linebreak: function(s, c) {
            return s.replace(/(?:\r\n|\r|\n)/g, c || '<br/>');
        }, // string, replace char
    },
    separator: function(n, c, l) {
        var l = l || 3;
        var r = new RegExp("\\B(?=(\\d{" + l + "})+(?!\\d))", "g");
        var p = n.toString().split(".");
        p[0] = p[0].replace(r, c || ",");
        return p.join(".");
    }, // string, separator char (,) , separator mask length (3)
    pad: function(n, l, c, r, b) {
        var s = n.toString(),
            l = l || 2,
            r = r !== false,
            b = b !== false;
        if (s.length > 0) {
            var p = '';
            if (!isNaN(n) && s.charAt(0) == '-') {
                p = '-';
                s = s.substring(1)
            };
            while (s.length < l) {
                if (r) s = (c || '0') + s;
                else s += (c || '0');
            }
            return b ? p + s : s;
        } else return '';
    }, // number, length (2), padder char (0), isPadLeft (YES), showNegative (YES)
    dayFormat: function(e) {
        return parseInt(e.substring(8, 10), 10).toString()
    },
    displayWeekday: function(d) {
        const weekday = ['日', '一', '二', '三', '四', '五', '六'];
        if (d.length > 10) {
            return weekday[new Date(`${d.split('T')[0]}T12:00:00+08:00`).getDay()]
        }

        return weekday[new Date(`${d}T12:00:00+08:00`).getDay()]
    },
    todayYMD: function(offset, date) {
        const today = date !== undefined ? new Date(date) : new Date();
        if (offset !== undefined && offset !== 0) {
            function addHours(d, n, reset) {
                const t = d.getTime();
                let newDate = new Date(d.setHours(d.getHours() + n));
                return void 0 !== reset && reset && d.setTime(t), newDate
            }
            addHours(today, 24 * offset);
        }
        return `${today.getFullYear()}-${today.getMonth() < 9 ? '0' + (today.getMonth()+1) : (today.getMonth()+1)}-${today.getDate() < 10 ? '0' + (today.getDate()) : (today.getDate())}`;
    },
    convertFileToBase64: function(file) {
        return new Promise((resolve, reject) => {
            if (file) {
                const reader = new FileReader();
                reader.onload = function() {
                    resolve(reader.result);
                }
                reader.onerror = function(error) {
                    reject(error);
                }
                reader.readAsDataURL(file);
            } else {
                reject(new Error('No file found'))
            }
        });
    }
}

Vue.prototype.RegExTester = new function() {

    this.regex = {
        numeric: /^[0-9]+$/i,
        alpha: /^[a-zA-Z]+$/,
        alphanumeric: /^[a-zA-Z0-9]+$/,
        email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, // min 8 char, at least 1 letter, 1 num
        phoneHK: /^[23456789][0-9]{7}$/,
        phoneHK_allowHyphenSpace: /^(^[2|3|4|5|6|7|8|9])+([0-9]{7}|([0-9]{3}[\s\-][0-9]{4}))$/,
        carNumberHK: /^(?:(?![I|O|Q])[A-Z0-9\s]){1,8}$/
    }

    this.test = function(s, name, isCustomRegex) {
        if (isCustomRegex === true) {
            return name.test(s);
        }
        return this.regex[name].test(s);
    }

}

Vue.prototype.FormChecker = new function() {
    const KEYS_CODE = {
        delete: 46,
        backspace: 8,
        insert: 45,
        arrow_left: 37,
        arrow_up: 38,
        arrow_right: 39,
        arrow_down: 40,
        space: 32,
        enter: 13,
        tab: 9,
        shift: 16,
        ctrl: 17,
        alt: 18
    };

    function backSpaceOverride(e, caretPos, caretEnd, separator) {

        var str = e.value;
        var caretSet = caretPos;

        if (caretPos == caretEnd) {
            if (caretPos > 0) {
                var char = str.charAt(caretPos - 1);

                if (char != separator) {
                    e.value = str.slice(0, caretPos - 1) + str.slice(caretPos);
                } else {
                    e.value = str.slice(0, caretPos - separator.length - 1) + str.slice(caretPos);
                }
                caretSet = caretPos - 1;
            }
        } else {
            e.value = str.slice(0, caretPos) + str.slice(caretEnd);
        }

        if ('createEvent' in document) {
            var event = document.createEvent('Event');
            event.initEvent('input', true, true);
            e.dispatchEvent(event);
        } else {
            e.fireEvent('oninput');
        }

        e.focus({ preventScroll: true });
        e.setSelectionRange(caretSet, caretSet);

    }

    function deleteOverride(e, caretPos, caretEnd, separator) {

        var str = e.value;
        var char = str.charAt(caretPos);

        if (caretPos == caretEnd) {

            if (char != separator) {
                e.value = str.slice(0, caretPos) + str.slice(caretPos + 1);
            } else {
                e.value = str.slice(0, caretPos) + str.slice(caretPos + separator.length);
            }

            e.focus({ preventScroll: true });
            e.setSelectionRange(caretPos, caretPos);
        } else {
            e.value = str.slice(0, caretPos) + str.slice(caretEnd);
        }


        if ('createEvent' in document) {
            var event = document.createEvent('Event');
            event.initEvent('input', true, true);
            e.dispatchEvent(event);
        } else {
            e.fireEvent('oninput');
        }

        e.focus({ preventScroll: true });
        e.setSelectionRange(caretPos, caretPos);

    }

    this.inputFormat = function(ev, separator) {
        if (ev.keyCode === KEYS_CODE.delete) {
            ev.preventDefault();
            deleteOverride(ev.target, ev.target.selectionStart, ev.target.selectionEnd, separator);
            return false;
        } else if (ev.keyCode === KEYS_CODE.backspace) {
            ev.preventDefault();
            backSpaceOverride(ev.target, ev.target.selectionStart, ev.target.selectionEnd, separator);
            return false;
        }
    }
}

Vue.prototype.Validator = new function() {

    function maskFormat(target, val, mask, separator, needFocus) {

        const limit = mask.reduce(function(a, b) { return a + b; }, 0);
        var str = '';
        var count = 0;

        for (let i = 0; i < val.length; i++) {
            const e = val[i];
            var id = mask[count];
            var prev = 0;
            for (var j = 0; j < count; j++) {
                prev += mask[j];
            }
            var pos = id + prev;

            if (i + 1 < pos) {
                str += e;
            } else if (count < mask.length - 1) {
                str += e + separator;
                count++;
            } else if (i < limit) {
                str += e;
            }
        }

        if (target !== undefined) {
            const currentCaret = target.selectionStart;

            if (currentCaret >= target.value.length) {
                target.value = str;
                if (!!needFocus) target.focus({ preventScroll: true });
            } else {
                target.value = str;
                if (!!needFocus) target.focus({ preventScroll: true });

                var tempSum = 0;
                var tempCaret = currentCaret;

                for (var i = 0; i < mask.length; i++) {
                    var maskEnd = tempSum + mask[i] + i;
                    if (currentCaret == maskEnd) {
                        tempCaret += 1;
                        break;
                    } else {
                        tempSum += mask[i];
                    }
                }
                target.setSelectionRange(tempCaret, tempCaret);
            }

        }
        return str;
    }

    this.transform = {
        uppercase: function(e) {
            const checkVal = e.value.toString();
            e.value = checkVal.toUpperCase();
        },
        lowercase: function(e) {
            const checkVal = e.value.toString();
            e.value = checkVal.toLowerCase();
        }
    }
    this.process = {
        numeric: function(e) {
            const checkVal = e.value.toString();
            let cleaned = Vue.prototype.Formatter.filter.numOnly(checkVal);
            e.value = cleaned;
            return true;
        },
        phoneHK: function(e, isMobile, needFocus) {
            const checkVal = e.value.toString();
            const cleaned = Vue.prototype.Formatter.filter.numOnly(checkVal);
            var formatted = maskFormat(e, cleaned, [4, 4], ' ', needFocus);
            e.value = formatted;
            if (isMobile) {
                return Vue.prototype.RegExTester.test(Vue.prototype.Formatter.filter.numOnly(formatted), 'phoneHKmobile');
            } else {
                return Vue.prototype.RegExTester.test(Vue.prototype.Formatter.filter.numOnly(formatted), 'phoneHK');
            }
        }
    }
}

Vue.prototype.$Encrypt = function Encrypt(value) {
    const key = CryptoJS.PBKDF2(keyValue, 'salt', { keySize: 256 / 32, iterations: 100 });
    const iv = CryptoJS.enc.Utf8.parse(ivKey); // Convert string to WordArray
    const encrypted = CryptoJS.AES.encrypt(value, key, { iv: iv, mode: CryptoJS.mode.CBC });
    return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
}

Vue.prototype.$Decrypt = function Decrypt(value) {
    const key = CryptoJS.PBKDF2(keyValue, 'salt', { keySize: 256 / 32, iterations: 100 });
    const iv = CryptoJS.enc.Utf8.parse(ivKey);
    const decrypted = CryptoJS.AES.decrypt({ ciphertext: CryptoJS.enc.Hex.parse(value) }, key, { iv: iv, mode: CryptoJS.mode.CBC });
    return decrypted.toString(CryptoJS.enc.Utf8);
}

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
    components: { Verte },
}).$mount('#app');